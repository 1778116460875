<template>
  <div class="empty">    

    <template v-if="useAuth().isOwner(owner)">

      <div class="start">

        <i class="icon-rocket rk"></i>

        <div class="txt">
          {{ $t('diary_info_start_first_week') }}
        </div>

        <div class="button" @click="$emit('choosecreate')">
          {{ $t('diary_info_get_it_rolling') }}
        </div>

      </div>

    </template>

    <template v-else>

      <div class="start">

        <i class="icon-rocket rk"></i>

        <div class="txt">
          {{ $t('diary_info_no_data_to_display') }}
        </div>        

      </div>

    </template>
    
    

  </div>
</template>

<script>
 

export default { 
  props: {
    owner: {
      type: Number
    }
  },
  components: {         
  },  
  data () {
    return {            
      
    }
  },
  created() {    
    
  },
  methods: {  
  }
}
</script>

<style scoped>


.start{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  margin-bottom: 9rem;
}
.start .rk{
  font-size: 3rem;
}
.start .txt{
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}



</style>
